import styled, { device, themeGet } from "@styled";

export const BoxSectionWrapper = styled.section`
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
  background-image: linear-gradient(
    320deg,
    ${themeGet("colors.secondaryDark")},
    ${themeGet("colors.secondary")}
  );
  ${device.medium} {
    padding: 110px 0;
  }
  .section-title {
    margin-bottom: 40px;
    h2 {
      color: #fff;
      padding: 0;
      border: none;
    }
    p {
      color: #fff;
    }
  }
  &#integrations-and-connectors-softwareapps-api {
    background: #f54e1c;
    .boxSectionWrap {
      display: none;
    }
    button {
      margin: 0;
    }
    .marketingList {
      li {
        &::before {
          background: #fff;
          border-color: #fff;
        }
      }
    }
  }
`;

export const BoxSectionInner = styled.div`
button {
  line-height: 1;
  margin: 50px auto 0;
  display: block;
  &.clicked {
      background-color: #fff;
      border-color: #fff;
      color: #e14629;
      .btn-icon {
          transform: rotate(90deg);
      }
  }
  .btn-icon {
      font-size: 26px;
      width: 14px;
      height: 14px;
      transition: transform 0.3s;
  }
}
.marketingList {
  li {
      padding-left: 1.5em;
      font-size: 16px;
      letter-spacing: 0px;
      color: #fff;
      font-family: 'Poppins';
      ${device.medium} {
        font-size: 20px;
    }
      &:before {
          content: "";
          position: absolute;
          top: 5px;
          left: 0;
          display: block;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: linear-gradient(-50deg,#5f1a7e 20%,#e14629 80%);
          border: 1px solid #fff;
      }
  }   
}
.additional-list {
  .marketingList {
      display: block;
      margin-top: 30px;
      ${device.medium} {
        margin-top: 50px;
      }
      li {
          &:not(:last-child) {
              margin-bottom: 20px;
          }
          &::before {
              background: #e14629;
              border-color: #e14629;
          }
      }
  }
}
`;

export const BoxSectionWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  ${device.medium} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${device.xlarge} {
    grid-template-columns: repeat(4, 1fr);
  }
  & > a {
    display: flex;
    text-decoration: none;
  }
`;
export const BoxSectionWrapInner = styled.div`
  width: 100%;
  padding: 30px 15px;
  border: 1px solid #fff;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  transition: ${themeGet("transition")};
  &:hover {
    box-shadow: 0 0 40px -16px rgb(255 255 255);
    transform: translateY(-3px);
  }
`;
export const BoxSectionIconwrap = styled.div``;
export const BoxSectionTextWrap = styled.div`
  h4 {
    margin: 20px 0;
    font-size: 20px;
    font-weight: bold;
    ${device.xxlarge} {
      font-size: 22px;
    }
  }
  p {
    color: rgb(255 255 255 / 70%);
    font-size: 14px;
    ${device.xlarge} {
      font-size: 15px;
    }
    ${device.xxlarge} {
      font-size: 17px;
    }
  }
`;